import { Variant, CountryCode } from '@dayetopia/types';
import { PAD_STACK_PRODUCT_IDS } from '@constants';
import { CurrencyObj, ProductCode, PadSizeType, PadsBoxStackState, PadsBoxState } from '@contracts';
import { getSubscriptionPlans } from './getSubscriptionPlans';
import {
  getAllPadTypes,
  getLinerVariantKey,
  getPadVariantKeys,
  getRegularVariantKey,
  getSuperPlusVariantKey,
  getSuperVariantKey,
  isPadsStackBox
} from './pads';
import { getDiscountedPrice, getNewProductPrice, getPadStackPrices, getProductPrice } from './price';
import { getProductPrices } from './prices';
import { getDiscountPercent } from './subscriptions';

export async function getLinerPadsPrice(country: CountryCode, currency: CurrencyObj) {
  const linerVariant = { [getLinerVariantKey(country)]: 1 } as Variant;

  const linerPadPrice = (await getBoxPrice(linerVariant, currency, country, ProductCode.Pads)) || 0;
  return linerPadPrice;
}

export async function getRegularPadsPrice(country: CountryCode, currency: CurrencyObj) {
  const regularVariant = { [getRegularVariantKey(country)]: 1 } as Variant;

  const regularPadPrice = (await getBoxPrice(regularVariant, currency, country, ProductCode.Pads)) || 0;
  return regularPadPrice;
}

export async function getSuperPadsPrice(country: CountryCode, currency: CurrencyObj) {
  const nightPadVariantPrice = { [getSuperVariantKey(country)]: 1 } as Variant;

  const nightPadPrice = (await getBoxPrice(nightPadVariantPrice, currency, country, ProductCode.Pads)) || 0;
  return nightPadPrice;
}

export async function getSuperPlusPadsPrice(country: CountryCode, currency: CurrencyObj) {
  const nightPlusPadVariant = { [getSuperPlusVariantKey(country)]: 1 } as Variant;

  const nightPlusPadPrice = (await getBoxPrice(nightPlusPadVariant, currency, country, ProductCode.Pads)) || 0;
  return nightPlusPadPrice;
}

export async function getPadsPrice(padsBoxStackState: PadsBoxStackState, padsBoxState: PadsBoxState, currency: CurrencyObj, country: CountryCode) {
  const finalPadsBoxState = isPadsStackBox(country)
    ? {
        [PAD_STACK_PRODUCT_IDS[PadSizeType.LINER]]: padsBoxStackState[PadSizeType.LINER],
        [PAD_STACK_PRODUCT_IDS[PadSizeType.REGULAR]]: padsBoxStackState[PadSizeType.REGULAR],
        [PAD_STACK_PRODUCT_IDS[PadSizeType.SUPER]]: padsBoxStackState[PadSizeType.SUPER],
        [PAD_STACK_PRODUCT_IDS[PadSizeType.SUPER_PLUS]]: padsBoxStackState[PadSizeType.SUPER_PLUS]
      }
    : padsBoxState;

  const productPrice = await getBoxPrice(finalPadsBoxState as Variant, currency, country, ProductCode.Pads);

  return productPrice || 0;
}

export async function getMinPrice(productCode: ProductCode, currency: CurrencyObj, country: CountryCode, variants?: Variant) {
  try {
    let maxDiscount = 0;
    let basePrice;
    let startingFrom = false;
    const plansProductCode = productCode === ProductCode.VMSEssential ? ProductCode.ScreeningKit : productCode;

    if (!plansProductCode) {
      return;
    }

    const plans = await getSubscriptionPlans(plansProductCode);
    if (plans) {
      maxDiscount = plans.reduce((max: number, subscription) => Math.max(max, getDiscountPercent(subscription, country)), 0);
    }

    let finalVariants: Variant | undefined = variants;

    if (productCode === ProductCode.TamponBox) {
      if (!finalVariants) {
        if (country === CountryCode.US) {
          finalVariants = { nakedregular: 18 } as Variant;
        } else {
          finalVariants = { nakedregular: 12 } as Variant;
        }
      }
      basePrice = getDiscountedPrice((await getBoxPrice(finalVariants, currency, country)) ?? -1, maxDiscount);
      startingFrom = true;
    } else if (productCode === ProductCode.Pads) {
      if (isPadsStackBox(country)) {
        basePrice = getDiscountedPrice((await getProductPrice(ProductCode.PadsBox12R, currency, country))?.price ?? -1, maxDiscount);
        startingFrom = true;
      } else {
        basePrice = await getNewProductPrice(currency, country, ProductCode.PadsBox16SP);
        startingFrom = true;
      }
    } else {
      basePrice = (await getProductPrice(productCode, currency, country)).price;
    }

    return {
      startingFrom,
      price: Number(basePrice?.toFixed(2))
    };
  } catch (e) {
    console.error(e);
  }
}

export async function getBoxPrice(variantsDict: Variant, currency: CurrencyObj, country: CountryCode, boxProductCode = 'tampon_box') {
  if (!variantsDict) return null;
  return getPrice(variantsDict, boxProductCode);

  function calculateVATPrice(price: number, vatPercent: number): number {
    if (vatPercent && vatPercent > 0) {
      const vatAmount = price * (vatPercent / 100);
      return vatAmount;
    }
    return 0;
  }

  async function getPrice(variants: any, productCode: string) {
    let price = 0.0;
    const pricesData = await getProductPrices();
    const padSizeTypes = getAllPadTypes();
    const isPadsStack = isPadsStackBox(country);
    const padsStackPrices = isPadsStack ? await getPadStackPrices(variants, currency, country) : null;
    const padVariantKeys = getPadVariantKeys(country);

    if (pricesData) {
      const product = pricesData.find((itm) => itm.id === productCode);
      if (product) {
        const productVariants = product.variants;
        const vatPercent = product?.vat[country] ?? 0;
        const variantsArray = Object.keys(variants).map((variant) => ({ variant, count: variants[variant] }));

        if (productVariants) {
          variantsArray.forEach(async (configurationItem) => {
            const isPadStackKeyIncluded = Object.values(padVariantKeys).includes(configurationItem.variant as ProductCode);
            const isLegacyPadKeyIncluded = padSizeTypes.includes(configurationItem.variant as PadSizeType);

            if (isPadStackKeyIncluded && padsStackPrices) {
              price += padsStackPrices[configurationItem.variant as PadSizeType];
            } else if (
              configurationItem.variant !== 'initiationbox' &&
              configurationItem.variant !== 'mask' &&
              configurationItem.variant !== 'proviotics' &&
              (!isPadsStack || !isLegacyPadKeyIncluded)
            ) {
              const match = productVariants.find((variant) => variant.id === configurationItem.variant);

              if (match) {
                const matchPrice = match.price.find((variant) => variant.currency === currency.letterCode);
                if (matchPrice) {
                  const VATPrice = calculateVATPrice(matchPrice.amount, vatPercent);
                  const finalItemPrice = (matchPrice.amount + VATPrice)?.toFixed(2);
                  price += configurationItem.count * Number(finalItemPrice);
                }
              }
            }
          });
        }
      }
    }

    return price;
  }
}
