import { Environment } from '@contracts';

/**
 * Gets the active environment, defaulting to 'development' if not set
 */
export const getActiveEnvironment = (): Environment => {
  return (process.env.GATSBY_ACTIVE_ENV as Environment) || 'development';
};

/**
 * Checks if the current environment is development.
 *
 * @returns {boolean} True if the environment is development, otherwise false.
 */
export const isDevelopmentEnvironment = (): boolean => {
  return getActiveEnvironment() === 'development';
};

/**
 * Checks if the current environment is test.
 *
 * @returns {boolean} True if the environment is test, otherwise false.
 */
export const isTestEnvironment = (): boolean => {
  return getActiveEnvironment() === 'test';
};

/**
 * Checks if the current environment is production.
 *
 * @returns {boolean} True if the environment is production, otherwise false.
 */
export const isProductionEnvironment = (): boolean => {
  return getActiveEnvironment() === 'prod';
};
