import { Dispatch, useCallback } from 'react';
import { Auth } from 'firebase/auth';
import useAuthFetch from '@components/useAuthFetch';
import { MainContextActionTypes } from '@constants';

/**
 * Loads credits info
 * @param firebaseAuth - The firebase auth object
 * @param dispatch - The dispatch function
 * @returns The load credits info function
 */
function useLoadCreditsInfo(firebaseAuth: Auth | null | undefined, dispatch: Dispatch<MainContextActionTypes>) {
  const authFetch = useAuthFetch(firebaseAuth);

  const loadCreditsInfo = useCallback(async () => {
    if (!firebaseAuth?.currentUser) {
      return;
    }

    try {
      const user = firebaseAuth?.currentUser;
      const creditData = await authFetch.get('/recurly/account-credit/', user);
      dispatch({ type: 'setCreditData', data: creditData });
    } catch (error) {
      console.error('Error fetching credit data:', error);
    }
  }, [firebaseAuth?.currentUser]);

  return loadCreditsInfo;
}

export default useLoadCreditsInfo;
