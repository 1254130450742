import { useEffect, Dispatch } from 'react';
import { initializeApp } from 'firebase/app';
import { browserLocalPersistence, indexedDBLocalPersistence, initializeAuth } from 'firebase/auth';
import { AppState, MainContextActionTypes } from '@constants';
import { getEcommerceData } from '@utils/ecommerce';
import { getProductPrices } from '@utils/prices';
import { hydrateInitialState } from '@utils/sessionStorage';
import useLocaleNavigation from '@utils/useLocaleNavigation';

function useLoadEcommerceData(state: AppState, initialStateWithCountry: AppState, dispatch: Dispatch<MainContextActionTypes>) {
  const { navigateToLocaleByCountryCode } = useLocaleNavigation();

  useEffect(() => {
    const initialStateWithSessionStorage: AppState = hydrateInitialState(initialStateWithCountry, ['country']);

    dispatch({
      type: 'REHYDRATE-FROM-SESSION-STORAGE',
      data: initialStateWithSessionStorage
    });

    if (!state.firebaseAuth) {
      const firebaseApp = initializeApp({
        apiKey: process.env.GOOGLE_API_KEY,
        authDomain: process.env.GOOGLE_AUTH_DOMAIN,
        projectId: process.env.GOOGLE_PROJECT_ID
      });
      const firebaseAuth = initializeAuth(firebaseApp, {
        persistence: [indexedDBLocalPersistence, browserLocalPersistence]
      });

      firebaseAuth.settings.appVerificationDisabledForTesting = process.env.DISABLE_RECAPTCHA === 'true';

      dispatch({ type: 'setFirebaseAuth', data: firebaseAuth });
    }

    getEcommerceData(initialStateWithCountry.chosenCountry || state.country, dispatch);

    (async () => {
      await getProductPrices();
    })();
  }, []);

  useEffect(() => {
    if (state.chosenCountry) {
      if (state.chosenCountry !== state.country) {
        dispatch({ type: 'setCountry', data: state.chosenCountry });
        dispatch({ type: 'cartClear' });
        getEcommerceData(state.chosenCountry, dispatch);
        navigateToLocaleByCountryCode(state.chosenCountry);
      }
    }
  }, [state.chosenCountry, state.country]);
}

export default useLoadEcommerceData;
