import { Dispatch, useEffect } from 'react';
import { AppState, MainContextActionTypes } from '@constants';

function useCheckOrderPrice(state: AppState, dispatch: Dispatch<MainContextActionTypes>) {
  useEffect(() => {
    const hasDiscount = !!state.discountAmount;
    const recurringDiscount = hasDiscount && state.discountExpiryTime;
    const discountAmount = state.discountAmount ?? 0;
    const totalPrice = state.selectedBox?.price || 0;
    let orderPrice: number = totalPrice;
    let subscriptionsPrice: number = totalPrice;

    if (state.cartItems.length > 0) {
      state.cartItems.forEach((item: any) => {
        const itemPrice = item.price * item.quantity;
        orderPrice += itemPrice;
      });
    }

    if (hasDiscount) {
      orderPrice -= discountAmount;
      if (recurringDiscount) subscriptionsPrice -= discountAmount;
    }

    if (state.tax) {
      orderPrice += state.tax;
    }

    dispatch({ type: 'setPrice', data: orderPrice });
    dispatch({ type: 'setRecurringPrice', data: subscriptionsPrice });
  }, [state.discountAmount, state.cartItems, state.selectedPlan, state.selectedBox, state.tax]);

  useEffect(() => {
    dispatch({ type: 'updateShippingCost', data: state.country });
  }, [state.cartItems, state.shippingFees, state.price]);
}

export default useCheckOrderPrice;
