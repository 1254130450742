import { useEffect } from 'react';
import { useAccountStore } from '@components/dashboard/context/UserDashboardContext';
import { AppState, MainContextActionTypes } from '@constants';
import { CartItemGiftType, ProductCode, PromotionObject } from '@contracts';
import { getFromLocalStorage } from '@utils/localStorage';

function useCheckCartItems(state: AppState, dispatch: React.Dispatch<MainContextActionTypes>) {
  const purchases = useAccountStore((accountState) => accountState.purchases);

  useEffect(() => {
    const promotions: PromotionObject[] = getFromLocalStorage('promotions', true) ?? [];
    if (!Array.isArray(promotions)) return;

    promotions.forEach((promo) => {
      const currentDate = new Date();
      const startDate = new Date(promo.startDate);
      const endDate = new Date(promo.endDate);
      const isPromoActive = currentDate >= startDate && currentDate <= endDate;

      const freeGift = state.cartItems.find((item) => item.id === `${promo.freeGift}_free` && item.giftType === CartItemGiftType.Promotion);
      if (freeGift && !isPromoActive) {
        dispatch({ type: 'cartItemRemove', data: freeGift });
      }
    });

    const tamponSubscription = purchases.subscriptions?.find((subscription) => subscription.planCode?.includes('tampon'));
    const tamponBoxCartItem = state.cartItems.find((item) => item.productCode === `${ProductCode.TamponBox}` && item.dependantCartItems);
    if (tamponSubscription && tamponBoxCartItem) {
      tamponBoxCartItem.dependantCartItems?.forEach((cartItem) => {
        dispatch({ type: 'cartItemRemove', data: cartItem });
      });
      dispatch({ type: 'cartItemDependantItemsRemove', data: tamponBoxCartItem });
    }
  }, [state.cartItems, purchases]);
}

export default useCheckCartItems;
