import { Dispatch, useEffect } from 'react';
import { AppState, MainContextActionTypes } from '@constants';
import { validateCartPromotions } from '@utils/cart';

function useValidateCartPromotions(state: AppState, dispatch: Dispatch<MainContextActionTypes>) {
  useEffect(() => {
    validateCartPromotions(state, dispatch);
  }, []);
}

export default useValidateCartPromotions;
