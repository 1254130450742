import { Dispatch } from 'react';
import { CountryCode } from '@dayetopia/types';
import { MainContextActionTypes } from '@constants';
import { ProductConfiguration, PromotionObject, ShippingObject } from '@contracts';
import packageJson from '../../package.json';
import getOmsUrl from './getOmsUrl';
import { setOnLocalStorage } from './localStorage';

export function getEcommerceData(countryCode: string, dispatch: Dispatch<MainContextActionTypes>) {
  const oms = getOmsUrl();
  const url = `${oms}/ecommerce/data`;
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json; charset=utf-8'
    }
  };
  fetch(url, options)
    .then((res) => res.json())
    .then((json) => {
      const { shippingFees, promotions, configurations } = json;
      const countryShippingFees =
        shippingFees?.find((item: { countryCode: string }) => item.countryCode === countryCode) ??
        shippingFees.find((item: { countryCode: string }) => item.countryCode === CountryCode.GB);
      if (!promotions || !configurations || !countryShippingFees) return;
      updateEcomData(promotions, configurations, dispatch, countryShippingFees);
    })
    .catch((err) => {
      throw err;
    });
}

function updateEcomData(
  promotions: PromotionObject[],
  configurations: ProductConfiguration[],
  dispatch: Dispatch<MainContextActionTypes>,
  countryShippingFees?: ShippingObject
) {
  const ecomDataTimestampKey = `ecomDataTimestamp-${packageJson.version}`;

  setOnLocalStorage('promotions', promotions, true);
  setOnLocalStorage('configurations', configurations, true);

  if (countryShippingFees) {
    const currency = {
      letterCode: countryShippingFees.currencyCode,
      symbol: countryShippingFees.currencySymbol
    };
    dispatch({ type: 'setCurrency', data: currency });
    dispatch({ type: 'setShippingFees', data: countryShippingFees.shippingFees });
    dispatch({ type: 'setFreeShippingRequirement', data: countryShippingFees.freeShippingRequirement ?? 50 });

    const date = new Date();
    setOnLocalStorage(ecomDataTimestampKey, date.getTime().toString());
  }
}
