import { useEffect } from 'react';
import { useLocation } from '@reach/router';
import { setOnLocalStorage } from '@utils/localStorage';

function useCheckParamsForReferralCode() {
  const location = useLocation();

  useEffect(() => {
    const { search } = location;

    const refCode = new URLSearchParams(location.search).get('refCode');

    if (refCode) {
      setOnLocalStorage('link-referral-code', refCode);
    }

    const brandbassadorRefCode = new URLSearchParams(search).get('ref');

    if (brandbassadorRefCode) {
      setOnLocalStorage('link-bb-ref', brandbassadorRefCode);
    }
  }, []);
}

export default useCheckParamsForReferralCode;
