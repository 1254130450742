import { CountryCode, ProductCodeKey, Subscription, SubscriptionPlan } from '@dayetopia/types';
import checkResStatus from '@utils/checkResStatus';
import getOmsUrl from '@utils/getOmsUrl';

export const getPlanPrice = (
  price: number,
  subscription: SubscriptionPlan,
  country: CountryCode,
  shippingPrice?: number,
  calculateBillingPrice?: boolean
) => {
  const billingPrice = calculateBillingPrice ? getBillingPriceForSubscription(subscription, price, country) : price;

  return calculateBillingPrice ? billingPrice + (shippingPrice ?? 0) : billingPrice;
};

export const getBillingPriceForSubscription = (subscription: SubscriptionPlan, boxPrice: number, country: CountryCode) => {
  const discountPercent = getDiscountPercent(subscription, country);
  const itemPrice = boxPrice - (boxPrice * discountPercent) / 100;
  const discountedPrice = subscription.productCode === ProductCodeKey.Pads ? (itemPrice * 20) / 20 : Math.round(itemPrice * 20) / 20;

  return (discountedPrice * subscription.subscriptionInterval.length) / subscription.billing.installments;
};

export const getSubscriptionPlans = async (productCode: string) => {
  const omsUrl = getOmsUrl();
  const url = `${omsUrl}/ecommerce/subscriptions/${productCode}`;
  const options = {
    method: 'GET'
  };

  try {
    const res = await fetch(url, options);
    const data = checkResStatus(res);
    return await data;
  } catch (err) {
    console.debug('Error: ', JSON.stringify(err));
    throw err;
  }
};

export const getDiscountPercent = (subscription: SubscriptionPlan, country: CountryCode) => {
  return subscription?.discountPercent?.[country] ?? subscription?.discountPercent?.default ?? 0;
};

export const isSubscriptionBundle = (subscription: Subscription) => {
  const { variants } = subscription;

  if (!variants) {
    return false;
  }

  return Object.keys(variants).some((key) => key.includes('_bundle_'));
};
