import { Dispatch, useCallback } from 'react';
import { useAccountStore } from '@components/dashboard/context/UserDashboardContext';
import { AppState, MainContextActionTypes } from '@constants';
import useLoadUserInfoForTerms from './useHandleMfaStateChanged';
import useHandleMfaStateChanged from './useHandleMfaStateChanged';
import useLoadCreditsInfo from './useLoadCreditsInfo';

function useLoadAllUserInfo(state: AppState, dispatch: Dispatch<MainContextActionTypes>) {
  const userInfo = useAccountStore((accountState) => accountState.userInfo);
  const loadUserInfoForTerms = useLoadUserInfoForTerms(state.firebaseAuth);
  const handleMfaStateChanged = useHandleMfaStateChanged(state.firebaseAuth);
  const loadCreditsInfo = useLoadCreditsInfo(state.firebaseAuth, dispatch);

  const loadAllUserInfo = useCallback(async () => {
    await loadUserInfoForTerms();
    await handleMfaStateChanged();

    if (!state.firebaseLoaded) {
      return;
    }

    loadCreditsInfo();
  }, [state.firebaseLoaded, state.firebaseAuth, userInfo.isMfaEnabled]);

  return loadAllUserInfo;
}

export default useLoadAllUserInfo;
