const i18n = require('./i18n');

exports.linkResolver = (doc) => {  
  if (!doc) {
    return '/';
  }
  
  const { type, uid, lang, data } = doc;
  
  // This fixes Prismic Preview redirecting to .../index when previewing the homepage
  if (uid === 'index') {
    return '/';
  }
  
  // URL for a category type
  if (type === 'articles' && data?.category?.uid) {
    return `/vitals/${data.category.uid}/${uid}/`;
  }

  // URL for a non-category type
  if (type === 'articles' && !data?.category?.uid) {
    return `/vitals/${uid}/`;
  }

  // URL for an author
  if (type === 'authors') {
    return `/vitals/bio/${uid}/`;
  }

  // URL for a page of type Product or Product Category
  if (type === 'product' || type === 'product_category') {
    const langConfig = i18n[lang];
    const prefix = langConfig && !langConfig.default ? `/${langConfig.path}` : '';
    return `${prefix}/products/${uid}/`;
  }
  
  // URL for a page of type Page
  if (type === 'page') {
    return `/${uid}/`;
  }
  
  // URL for a page of type VMS Landing Page
  if (type === 'vms_landing_page') {
    return `/${uid}/`;
  }

  // Backup for all other types
  return `/`;
};
