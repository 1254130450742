import { Auth, multiFactor } from 'firebase/auth';
import { useAccountStore } from '@components/dashboard/context/UserDashboardContext';

/**
 * Loads user info for terms and conditions
 * @param firebaseAuth - The firebase auth object
 * @returns The load user info for terms function
 */
function useHandleMfaStateChanged(firebaseAuth: Auth | null | undefined) {
  const userInfo = useAccountStore((accountState) => accountState.userInfo);
  const setUserInfo = useAccountStore((accountState) => accountState.setUserInfo);

  const handleMfaStateChanged = async () => {
    const currentUser = firebaseAuth?.currentUser;
    const isMfaEnabled = currentUser ? multiFactor(currentUser).enrolledFactors?.length > 0 : false;

    setUserInfo({ ...userInfo, isMfaEnabled });
  };

  return handleMfaStateChanged;
}

export default useHandleMfaStateChanged;
