import { Dispatch, useEffect } from 'react';
import { useLocation } from '@reach/router';
import { onAuthStateChanged, User } from 'firebase/auth';
import { useAccountStore } from '@components/dashboard/context/UserDashboardContext';
import { AppState, MainContextActionTypes } from '@constants';
import { setSentryUser } from '@utils/sentry';
import { identifyUser } from '@utils/tracking';
import useLoadAllUserInfo from './useLoadAllUserInfo';

function useLoadAndHandleUserInfo(state: AppState, dispatch: Dispatch<MainContextActionTypes>) {
  const resetState = useAccountStore((accountState) => accountState.reset);
  const userInfo = useAccountStore((accountState) => accountState.userInfo);
  const loadAllUserInfo = useLoadAllUserInfo(state, dispatch);
  const location = useLocation();

  useEffect(() => {
    // Initialization check for current user
    const currentUser = state.firebaseAuth?.currentUser;
    if (currentUser !== undefined && state.loggedIn === null) {
      dispatch({ type: 'setLoggedIn', data: !!currentUser });
    }
  }, [state.firebaseAuth]);

  useEffect(() => {
    // Guard clause to prevent running before firebaseAuth is initialized.
    if (!state.firebaseAuth || state.loggedIn === null) return () => {};

    const unsubscribe = onAuthStateChanged(state.firebaseAuth, async (user: User | null) => {
      dispatch({ type: 'setFirebaseLoaded', data: true });

      if (user) {
        dispatch({ type: 'setUserId', data: user.uid });
        dispatch({ type: 'setLoggedIn', data: true });
        dispatch({ type: 'setUserIsAnonymous', data: user.isAnonymous });

        if (user.email) {
          identifyUser(user.email);
        }

        setSentryUser(user.uid);

        if (state.loggedIn && !userInfo.accountId) {
          await loadAllUserInfo();
        }
      } else {
        resetState();
        dispatch({ type: 'setUserId', data: '' });
        dispatch({ type: 'setLoggedIn', data: false });

        if (state.loggedIn && !state.userIsAnonymous) {
          dispatch({ type: 'cartClear' });
        }
      }
    });

    return () => {
      unsubscribe();
    };
  }, [state.firebaseAuth, state.loggedIn, location.pathname]);
}

export default useLoadAndHandleUserInfo;
