import { CountryCode } from '@dayetopia/types';
import { CartItem, CartItemGiftType, CartItemType, CurrencyObj, ProductCode, PromotionObject, SubscriptionCartItem } from '@contracts';
import { isPromotionActive } from './contextHelpers';
import { getFromLocalStorage } from './localStorage';

/**
 * Retrieves dependant free gifts for a given cart item.
 * @param {CartItem[]} adjustedCartItems - The adjusted cart items.
 * @param {CartItem} cartItem - The cart item to retrieve dependant free gifts for.
 * @param {CountryCode} country - The country code.
 * @param {CurrencyObj} currency - The currency object.
 * @returns {CartItem[]} The dependant free gifts.
 */
export function getDependantFreeGifts(cartItem: CartItem, country: CountryCode, currency: CurrencyObj, price: number) {
  return getFreeGiftsForPromotion(cartItem, country, currency, price);
}

/**
 * Checks for and returns any free gifts that should be added based on active promotions.
 *
 * @param cartItem - The cart item being added/modified that triggered this check
 * @param country - The current country code (e.g. 'GB', 'US')
 * @param currency - The currency object containing letterCode and symbol
 * @param price - The current cart total price
 * @returns Array of CartItem objects representing free gifts to be added
 */
export const getFreeGiftsForPromotion = (cartItem: CartItem, country: CountryCode, currency: CurrencyObj, price: number): CartItem[] => {
  const freeGifts: CartItem[] = [];
  const promotions: PromotionObject[] = getFromLocalStorage('promotions', true) ?? [];

  promotions.forEach((promo) => {
    const isPromoActive = isPromotionActive(promo, country, cartItem.productCode);
    const cartItemIsFreeGift = cartItem.id?.includes('_free');
    const meetsSpendRequirement = promo.spendRequirement ? price >= promo.spendRequirement[country] : true;
    const isValidPromotion = (!promo.couponCode && meetsSpendRequirement) || promo.couponCode;
    const shouldAddFreeGift = isPromoActive && promo.freeGift && !cartItemIsFreeGift && isValidPromotion;

    if (shouldAddFreeGift) {
      const freeGiftProductCode = promo.freeGift as ProductCode;
      const freeGift = createFreeGiftWithProductCode(freeGiftProductCode, country, currency, CartItemGiftType.PromotionCode);
      freeGifts.push(freeGift);
    }
  });

  return freeGifts;
};

/**
 * Creates a free gift with a given product code.
 * @param {ProductCode} productCode - The product code.
 * @param {CountryCode} country - The country code.
 * @param {CurrencyObj} currency - The currency object.
 * @param {CartItemGiftType} type - The type of gift.
 * @returns {CartItem} The free gift.
 */
export function createFreeGiftWithProductCode(productCode: ProductCode, country: CountryCode, currency: CurrencyObj, type: CartItemGiftType) {
  let originalPrice = 0;
  const pricesData = getFromLocalStorage('productPrices', true);
  if (pricesData && Array.isArray(pricesData)) {
    const product = pricesData.find((itm) => itm.id === productCode);
    if (product && product.price) {
      const vatPercent = product?.vat[country] ?? 0;
      const price = product.price.find((p) => p.currency === currency.letterCode);
      if (price) {
        const vatAmount = price.amount * (vatPercent / 100);
        const finalPrice = price.amount + vatAmount;
        originalPrice = finalPrice;
      }
    }
  }
  return new CartItem(`${productCode}_free`, productCode, 0, 1, CartItemType.OneOffPurchase, false, type, originalPrice);
}

/**
 * Handles free gifts for a given cart item.
 * @param {CartItem[]} cartItems - The cart items.
 * @param {CartItem} cartItem - The cart item to handle free gifts for.
 * @param {CountryCode} country - The country code.
 * @param {CurrencyObj} currency - The currency object.
 * @returns {CartItem[]} The free gifts.
 */
export const handleFreeGifts = (cartItems: CartItem[], cartItem: CartItem, country: CountryCode, currency: CurrencyObj) => {
  const freeGifts: CartItem[] = [];
  const promotions: PromotionObject[] = getFromLocalStorage('promotions', true) ?? [];

  if (!Array.isArray(promotions)) return [];

  promotions.forEach((promo) => {
    const isPromoActive = isPromotionActive(promo, country, cartItem.productCode);
    const cartItemIsFreeGift = cartItem.id.includes('_free');
    const shouldAddFreeGift = isPromoActive && promo.freeGift && !promo.couponCode && !cartItemIsFreeGift;

    if (!shouldAddFreeGift) {
      return;
    }

    const isFreeGiftNotInCart = (promoId: string) => !cartItems.find((item) => item.id === `${promoId}_free`);
    const addFreeGift = () => {
      if (promo.freeGift && isFreeGiftNotInCart(promo.freeGift)) {
        const freeGift = createFreeGiftWithProductCode(promo.freeGift as ProductCode, country, currency, CartItemGiftType.PromotionCode);
        freeGifts.push(freeGift);
      }
    };

    if (cartItem.type === CartItemType.Subscription) {
      const planCode = (cartItem as SubscriptionCartItem).selectedPlan?.planCode ?? '';
      const isMultiMonthPlan = promo.planCodes?.some((code) => planCode.includes(code));
      if (isMultiMonthPlan) {
        addFreeGift();
      }
    } else {
      addFreeGift();
    }
  });

  return freeGifts;
};
