import axios from 'axios';
import { ProductPrice, ProductPriceData } from '@contracts';
import { getFromLocalStorage, setOnLocalStorage } from '@utils/localStorage';
import getOmsUrl from './getOmsUrl';

/**
 * Compares two price arrays to determine if they are equal.
 * @param {string[]} price1 - First price array consisting of [dollars, cents].
 * @param {string[]} price2 - Second price array consisting of [dollars, cents].
 * @returns {boolean} True if both price arrays are identical, false otherwise.
 */
export const arePriceArraysEqual = (price1: [string, string], price2: [string, string]): boolean => {
  return price1[0] === price2[0] && price1[1] === price2[1];
};

/**
 * Fetches product prices from the OMS and caches them in local storage.
 * @returns {Promise<any>} A promise that resolves to the product prices.
 */
export async function getProductPrices(): Promise<ProductPriceData[]> {
  const productPriceTimestamp = getFromLocalStorage('productPriceTimestamp') || 0;
  const timestampNow = new Date().getTime();
  const timeDiff = (timestampNow - Number(productPriceTimestamp)) / 1000;

  if (timeDiff < 60 && getFromLocalStorage('productPrices', true)) {
    return getFromLocalStorage('productPrices', true);
  }

  setOnLocalStorage('productPriceTimestamp', String(timestampNow));

  const oms = getOmsUrl();
  const url = `${oms}/products/prices`;

  const response = await axios.get(url, {
    headers: {
      'Content-Type': 'application/json; charset=utf-8'
    }
  });

  const items = response.data;
  setOnLocalStorage('productPrices', items, true);
  return items;
}

export function getProductPricesFromStorage() {
  const pricesData = getFromLocalStorage('productPrices', true) || [];

  return pricesData as ProductPrice[];
}
