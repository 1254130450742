import * as Sentry from '@sentry/react';
import { CaptureContext } from '@sentry/types';
import { getActiveEnvironment, isProductionEnvironment } from './environment';

export const initSentry = () => {
  if (process.env.DISABLE_SENTRY) {
    return;
  }

  Sentry.init({
    dsn: process.env.SENTRY_DSN_URL,
    environment: getActiveEnvironment(),
    tracesSampleRate: isProductionEnvironment() ? 0.1 : 1.0,
    integrations: [new Sentry.BrowserTracing()]
  });
};

export const sentryCaptureException = (error: any, context: CaptureContext = {}) => {
  console.debug('Sentry capture exception', error, context);
  Sentry.captureException(error, context);
};

export const sentryCaptureMessage = (message: string, context: CaptureContext = {}) => {
  console.debug('Sentry capture message', message, context);
  Sentry.captureMessage(message, context);
};

export const setSentryUser = (userId: string) => {
  console.debug('Setting Sentry user', userId);
  Sentry.configureScope((scope) => {
    scope.setUser({ id: userId });
  });
};
