import React, { useReducer } from 'react';
import { CountryCode } from '@dayetopia/types';
import { AppState, MainContext, MainContextActionTypes } from '@constants';
import { CartItem, ProductCode } from '@contracts';
import useCheckCartItems from '@hooks/useCheckCartItems';
import useCheckOrderPrice from '@hooks/useCheckOrderPrice';
import useCheckParamsForReferralCode from '@hooks/useCheckParamsForReferralCode';
import useInitSentry from '@hooks/useInitSentry';
import useLoadAndHandleUserInfo from '@hooks/useLoadAndHandleUserInfo';
import useLoadEcommerceData from '@hooks/useLoadEcommerceData';
import useValidateCartPromotions from '@hooks/useValidateCartPromotions';
import {
  calculateTotalPrice,
  handleCartItemAdd,
  handleCartItemDependentItemsRemove,
  handleCartItemRemove,
  removeInvalidFreeGifts,
  updateCartSession
} from '@utils/cart';
import { getFreeGiftsForPromotion } from '@utils/freeGift';
import { setOnLocalStorage } from '@utils/localStorage';
import { clearSessionStorage, getFromStorage, setOnSessionStorage } from '@utils/sessionStorage';
import { getShippingCostValue } from '@utils/shippingCost';

export const initialState: AppState = {
  firebaseAuth: undefined,
  firebaseDb: undefined,
  firebaseLoaded: false,
  loggedIn: null,
  userId: '',
  userIsAnonymous: false,
  selectedPlan: '28',
  selectedBox: {
    boxSize: 12,
    cbdregular: 0,
    cbdsuper: 6,
    nakedregular: 3,
    nakedsuper: 3,
    price: undefined
  },
  recommendedBox: undefined,
  cartCount: 0,
  cartItems: [],
  statusMessage: false,
  discountExpiryTime: undefined,
  discountAmount: undefined,
  price: 0,
  tax: undefined,
  recurringPrice: 0,
  creditData: null,
  currency: {
    symbol: '£',
    letterCode: 'GBP'
  },
  country: CountryCode.GB,
  chosenCountry: null,
  mfaVerifyId: undefined,
  freeShippingRequirement: 50,
  shippingCost: { originalShippingPrice: 0, shippingPrice: 0 },
  shippingFees: {
    [ProductCode.BlmPin]: { originalShippingPrice: 1, shippingPrice: 1 },
    [ProductCode.GiftCard]: { originalShippingPrice: 0, shippingPrice: 0 },
    [ProductCode.HolidaySampler]: { originalShippingPrice: 0, shippingPrice: 0 },
    [ProductCode.MaskBlue]: { originalShippingPrice: 2, shippingPrice: 2 },
    [ProductCode.MaskDark]: { originalShippingPrice: 2, shippingPrice: 2 },
    [ProductCode.MaskLight]: { originalShippingPrice: 2, shippingPrice: 2 },
    [ProductCode.MaskPink]: { originalShippingPrice: 2, shippingPrice: 2 },
    [ProductCode.Proviotics]: { originalShippingPrice: 2, shippingPrice: 2 },
    [ProductCode.Sampler]: { originalShippingPrice: 0, shippingPrice: 0 },
    [ProductCode.TamponBox]: { originalShippingPrice: 2.8, shippingPrice: 0 },
    [ProductCode.TamponPouch]: { originalShippingPrice: 0, shippingPrice: 0 },
    [ProductCode.TamponTin]: { originalShippingPrice: 2, shippingPrice: 2 },
    [ProductCode.USWaitlistPrepaid12]: { originalShippingPrice: 0, shippingPrice: 0 },
    [ProductCode.USWaitlistPrepaid18]: { originalShippingPrice: 0, shippingPrice: 0 },
    [ProductCode.VaginaMattersBook]: { originalShippingPrice: 2, shippingPrice: 0 },
    [ProductCode.ValentinesBox]: { originalShippingPrice: 2, shippingPrice: 2 },
    [ProductCode.ValentinesSampler]: { originalShippingPrice: 0, shippingPrice: 0 },
    [ProductCode.Masks]: { originalShippingPrice: 0, shippingPrice: 0 },
    [ProductCode.ScreeningKit]: { originalShippingPrice: 0, shippingPrice: 0 },
    [ProductCode.RoseCandle]: { originalShippingPrice: 2.5, shippingPrice: 2.5 }
  }
};

const mockDispatch = (a: MainContextActionTypes) => {
  console.debug(a);
};

const initial: MainContext = {
  state: initialState,
  dispatch: mockDispatch
};

const Context = React.createContext(initial);

function reducer(state: AppState, action: MainContextActionTypes): AppState {
  switch (action.type) {
    case 'setFirebaseAuth':
      return {
        ...state,
        firebaseAuth: action.data
      };
    case 'setFirebaseDb':
      return {
        ...state,
        firebaseDb: action.data
      };
    case 'setFirebaseLoaded':
      return {
        ...state,
        firebaseLoaded: action.data
      };
    case 'setLoggedIn':
      return {
        ...state,
        loggedIn: action.data
      };
    case 'setUserId':
      return {
        ...state,
        userId: action.data
      };
    case 'setUserIsAnonymous':
      return {
        ...state,
        userIsAnonymous: action.data
      };
    case 'setSelectedPlan':
      setOnSessionStorage('selectedPlan', action.data);
      return {
        ...state,
        selectedPlan: action.data
      };
    case 'setSelectedBox':
      setOnSessionStorage('selectedBox', action.data);
      return {
        ...state,
        selectedBox: action.data
      };
    case 'cartItemAdd': {
      const adjustedItems = handleCartItemAdd(state, state.cartItems, action.data);
      updateCartSession(adjustedItems);
      return {
        ...state,
        cartItems: adjustedItems
      };
    }
    case 'cartItemRemove': {
      const adjustedItems = handleCartItemRemove(state, state.cartItems, action.data);
      const cartItemsWithValidGifts = removeInvalidFreeGifts(adjustedItems, state, action.data);
      updateCartSession(cartItemsWithValidGifts);
      return {
        ...state,
        cartItems: cartItemsWithValidGifts
      };
    }
    case 'cartItemDependantItemsRemove': {
      const adjustedItems = handleCartItemDependentItemsRemove(state.cartItems, action.data);
      updateCartSession(adjustedItems);
      return {
        ...state,
        cartItems: adjustedItems
      };
    }
    case 'cartItemUpdate': {
      let adjustedItems = handleCartItemRemove(state, state.cartItems, action.data);
      adjustedItems = handleCartItemAdd(state, adjustedItems, action.data);
      updateCartSession(adjustedItems);
      return {
        ...state,
        cartItems: adjustedItems
      };
    }
    case 'cartItemQuantityIncrease': {
      const itemIndex = state.cartItems.findIndex((item: CartItem) => item.id === action.data.id && item.type === action.data.type);
      const updatedCartItems = [...state.cartItems];
      updatedCartItems[itemIndex].quantity += 1;

      const newPrice = calculateTotalPrice(updatedCartItems);

      const freeGifts = getFreeGiftsForPromotion(action.data, state.country, state.currency, newPrice);
      const newFreeGifts = freeGifts.filter((gift) => !updatedCartItems.some((item) => item.id === gift.id));

      updatedCartItems.push(...newFreeGifts);
      updateCartSession(updatedCartItems);

      return {
        ...state,
        cartItems: [...updatedCartItems],
        price: newPrice
      };
    }
    case 'cartItemQuantityDecrease': {
      const itemIndex = state.cartItems.findIndex((item: CartItem) => item.id === action.data.id && item.type === action.data.type);

      if (itemIndex === -1) {
        console.error('Item not found in cart');
        return state;
      }

      let updatedCartItems = [...state.cartItems];
      if (updatedCartItems[itemIndex].quantity === 1) {
        return state;
      }

      updatedCartItems[itemIndex].quantity -= 1;

      const newPrice = calculateTotalPrice(updatedCartItems);

      // Check and remove any invalid free gifts using new price
      updatedCartItems = removeInvalidFreeGifts(updatedCartItems, { ...state, price: newPrice }, action.data);
      updateCartSession(updatedCartItems);

      return {
        ...state,
        cartItems: updatedCartItems,
        price: newPrice
      };
    }
    case 'cartClear':
      updateCartSession([]);
      return {
        ...state,
        cartItems: []
      };
    case 'setPrice':
      return {
        ...state,
        price: action.data
      };
    case 'setTax':
      return {
        ...state,
        tax: action.data
      };
    case 'setRecurringPrice':
      return {
        ...state,
        recurringPrice: action.data
      };
    case 'setFreeShippingRequirement':
      return {
        ...state,
        freeShippingRequirement: action.data
      };
    case 'setRecommendedBox':
      setOnSessionStorage('recommendedBox', action.data);
      return {
        ...state,
        recommendedBox: action.data
      };
    case 'setStatusMessage':
      return {
        ...state,
        statusMessage: action.data
      };
    case 'setDiscountExpiryTime':
      return {
        ...state,
        discountExpiryTime: action.data
      };
    case 'setDiscountAmount':
      return {
        ...state,
        discountAmount: action.data
      };
    case 'setCurrency':
      setOnSessionStorage('currency', action.data);
      return {
        ...state,
        currency: action.data
      };
    case 'setCountry':
      return {
        ...state,
        country: action.data
      };
    case 'setChosenCountry':
      setOnLocalStorage('chosenCountry', action.data, true);
      return {
        ...state,
        chosenCountry: action.data
      };
    case 'setMfaVerifyId':
      setOnSessionStorage('mfaVerifyId', action.data);
      return {
        ...state,
        mfaVerifyId: action.data
      };
    case 'updateShippingCost': {
      const shippingCostValue = getShippingCostValue(state);
      setOnSessionStorage('shippingCost', shippingCostValue);

      return {
        ...state,
        shippingCost: shippingCostValue
      };
    }
    case 'setShippingFees':
      setOnSessionStorage('shippingFees', action.data);
      return {
        ...state,
        shippingFees: action.data
      };
    case 'REHYDRATE-FROM-SESSION-STORAGE':
      return {
        ...action.data
      };
    case 'CLEAR-STATE-AND-STORAGE': {
      const persistOnSession = [
        'selectedBox',
        'recommendedBox',
        'selectedPlan',
        'cartCount',
        'cartItems',
        'price',
        'tax',
        'recurringPrice',
        'freeShippingRequirement',
        'currency',
        'chosenCountry',
        'mfaVerifyId',
        'shippingCost',
        'shippingFees',
        'firebaseAuth',
        'loggedIn'
      ];
      clearSessionStorage(initialState, persistOnSession);

      return {
        ...initialState,
        firebaseAuth: state.firebaseAuth,
        loggedIn: state.loggedIn,
        firebaseDb: state.firebaseDb,
        firebaseLoaded: state.firebaseLoaded,
        selectedBox: state.selectedBox,
        recommendedBox: state.recommendedBox,
        selectedPlan: state.selectedPlan,
        cartCount: state.cartCount,
        cartItems: state.cartItems,
        price: state.price,
        tax: state.tax,
        recurringPrice: state.recurringPrice,
        freeShippingRequirement: state.freeShippingRequirement,
        currency: state.currency,
        mfaVerifyId: state.mfaVerifyId,
        country: state.country,
        chosenCountry: state.chosenCountry,
        shippingCost: state.shippingCost,
        shippingFees: state.shippingFees
      };
    }
    case 'setCreditData':
      return {
        ...state,
        creditData: action.data
      };
    default:
      throw new Error(JSON.stringify(action, null, 2));
  }
}

interface Props {
  children: React.ReactNode;
  country: CountryCode;
}

function Provider({ children, country }: Props) {
  const initialChosenCountry = getFromStorage('chosenCountry', true) as CountryCode;
  const initialStateWithCountry = {
    ...initialState,
    country,
    chosenCountry: initialChosenCountry
  } as AppState;
  const [state, dispatch] = useReducer(reducer, initialStateWithCountry);

  useInitSentry();

  useLoadEcommerceData(state, initialStateWithCountry, dispatch);
  useLoadAndHandleUserInfo(state, dispatch);

  useCheckParamsForReferralCode();
  useValidateCartPromotions(state, dispatch);
  useCheckCartItems(state, dispatch);
  useCheckOrderPrice(state, dispatch);

  return (
    <Context.Provider
      value={{
        state,
        dispatch
      }}
    >
      {children}
    </Context.Provider>
  );
}

export { Context, Provider };
