import { CountryCode, CurrencyCode, Variant } from '@dayetopia/types';
import { PAD_STACK_PRODUCT_IDS } from '@constants';
import { CurrencyObj, PadSizeType, PriceComplex, PriceSimple, PriceTextComponents } from '@contracts';
import { getProductPrices } from './prices';

/**
 * Retrieves the price as a string based on currency and whether the service is prepaid. Handles undefined prices.
 * @param {PriceComplex | undefined} price - The price information, which can be undefined, a string, or an array of currency-amount pairs.
 * @param {CurrencyObj} currency - The currency object containing the letter code to match in the price object.
 * @param {boolean} [isPrepaid=false] - Optional. Specifies whether the price calculation should consider the item as prepaid.
 * @returns {PriceSimple} The price as a string. If the price is prepaid or undefined, returns '0.00'.
 */
export const getPriceString = (price: PriceComplex | undefined, currency: CurrencyObj, isPrepaid?: boolean): PriceSimple => {
  const priceByCurrency = Array.isArray(price) ? price?.find((p) => p.currency === currency.letterCode)?.amount : price;

  return determineDisplayPrice(priceByCurrency, isPrepaid);
};

/**
 * Converts the price into an array of [dollars, cents] based on currency and whether the service is prepaid.
 * @param {PriceComplex | undefined} price - The price information, which can be undefined, a string, or an array of currency-amount pairs.
 * @param {CurrencyObj} currency - The currency object to filter the price by.
 * @param {boolean} [isPrepaid=false] - Optional. Determines if the price should be considered as prepaid.
 * @returns {[string, string]} An array where the first element is dollars and the second is cents. If prepaid or undefined, returns ['0', '00'].
 */
export const getPriceArray = (price: PriceComplex | undefined, currency: CurrencyObj, isPrepaid?: boolean): [string, string] => {
  const priceString = getPriceString(price, currency, isPrepaid);
  const [dollars, cents = '00'] = priceString.split('.');

  return [dollars, cents];
};

/**
 * Determines the display format of the price, considering if it is prepaid.
 * @param {PriceSimple} [price] - Optional. The price as a simple string, which can be undefined.
 * @param {boolean} [isPrepaid=false] - Optional. If true, or if the price is undefined, the function returns '0.00', indicating no charge or non-applicability.
 * @returns {PriceSimple} A string representing the formatted price, '0.00' if prepaid or undefined, or the original price if not.
 */
export const determineDisplayPrice = (price?: PriceSimple, isPrepaid?: boolean): PriceSimple => {
  if (isPrepaid || !price) {
    return '0.00';
  }

  return price;
};

/**
 * Compares two price arrays to determine if they are equal.
 * @param {string[]} price1 - First price array consisting of [dollars, cents].
 * @param {string[]} price2 - Second price array consisting of [dollars, cents].
 * @returns {boolean} True if both price arrays are identical, false otherwise.
 */
export const arePriceArraysEqual = (price1: [string, string], price2: [string, string]): boolean => {
  return price1[0] === price2[0] && price1[1] === price2[1];
};

/**
 * Formats a price based on the country and currency provided.
 * @param {PriceComplex} price - The price to be formatted, as an object with properties for dollars and cents.
 * @param {CurrencyObj} currency - The currency to be used, as an object with properties for symbol and code.
 * @param {CountryCode} country - The country code to determine the format of the price.
 * @returns {string} The formatted price string. If the country is GB or US, the currency symbol precedes the price. Otherwise, the currency symbol follows the price.
 */
export const formatPriceBasedOnCountry = (price: PriceComplex, currency: CurrencyObj, country: CountryCode): string => {
  const priceStr = getPriceString(price, currency);
  if (country === CountryCode.GB || country === CountryCode.US) {
    return `${currency.symbol}${priceStr}`;
  }
  return `${priceStr}${currency.symbol}`;
};

/**
 * Formats a price based on the currency provided.
 * @param {PriceComplex | number | string} price - The price to be formatted, which can be an object, a number, or a string.
 * @param {CurrencyObj} currency - The currency to be used, as an object with properties for symbol and code.
 * @param {boolean} [isPrepaid=false] - Optional. Determines if the price should be considered as prepaid.
 * @returns {string} The formatted price string. If the currency is GBP or USD, the currency symbol precedes the price. Otherwise, the currency symbol follows the price.
 */
export const formatPriceBasedOnCurrency = (price: PriceComplex | number | string | undefined, currency: CurrencyObj, isPrepaid?: boolean): string => {
  const priceString = typeof price === 'object' ? getPriceString(price, currency, isPrepaid) : formatPrice(price || -1);

  if (currency.letterCode === CurrencyCode.GBP || currency.letterCode === CurrencyCode.USD) {
    return `${currency.symbol}${priceString}`;
  }

  return `${priceString}${currency.symbol}`;
};

/**
 * Converts a price to a string with two decimal places.
 * @param {string | number} price - The price to be converted.
 * @returns {string} The price as a string with two decimal places.
 */
export const formatPrice = (price: string | number): string => {
  return parseFloat(price.toString()).toFixed(2);
};

/**
 * Splits and formats the price into main price and coins parts.
 * @param {string | number} price - The price to be formatted.
 * @returns {{ mainPrice: string, coinsPrice: string, showCoins: boolean }} An object containing the main price, coins price, and a boolean indicating if coins should be shown.
 */
export const getDisplayPriceParts = (price: string | number): { mainPrice: string; coinsPrice: string; showCoins: boolean } => {
  const displayPrice = formatPrice(price).split('.');

  if (displayPrice.length === 1) {
    displayPrice.push('00');
  }

  const mainPrice = displayPrice[0];
  const coinsPrice = displayPrice[1].length !== 1 ? displayPrice[1] : `${displayPrice[1]}0`;
  const showCoins = coinsPrice !== '00';

  return { mainPrice, coinsPrice, showCoins };
};

export const roundNumber = (number: number) => Math.round(number * 10) / 10;

export const getNewProductPrice = async (currency: CurrencyObj, country: CountryCode, productId: string, subscriptionPlan?: string) => {
  const pricesData = await getProductPrices();

  if (pricesData && Array.isArray(pricesData)) {
    const product = pricesData.find((item: { id: string }) => item.id === productId);

    if (product) {
      // Calculate VAT percentage
      const vatPercent = product.vat ? product.vat[country] ?? 0 : 0;

      let priceItem;
      if (product.pricePerPlan && subscriptionPlan) {
        const priceForPlan = product.pricePerPlan.find((plan: { planType: string }) => plan.planType === subscriptionPlan);
        priceItem = priceForPlan?.price.find((item: { amount: number; currency: string }) => item.currency === currency.letterCode);
      } else {
        priceItem = product.price?.find((item: { amount: number; currency: string }) => item.currency === currency.letterCode);
      }

      if (priceItem) {
        const vatAmount = priceItem.amount * (vatPercent / 100);
        return Number((priceItem.amount + vatAmount).toFixed(2));
      }
    }
  }

  return -1;
};

export const getDiscountedPrice = (originalPrice: number, discountPercentage: number): number => {
  const itemPrice = originalPrice - (originalPrice * discountPercentage) / 100;
  return Math.round(itemPrice * 20) / 20;
};

export async function getProductPrice(productId: string, currency: CurrencyObj, country: string) {
  const productCode = productId.includes('mask') ? 'mask' : productId;
  const pricesData = await getProductPrices();

  if (pricesData) {
    const product = pricesData.find((itm) => itm.id === productCode);
    const firstVariant = product?.variants?.[0];
    const productPrice = product?.price || firstVariant?.price;

    if (productPrice) {
      const vatPercent = product?.vat[country] ?? 0;
      const price = productPrice.find((p) => p.currency === currency.letterCode);

      if (price) {
        const vatAmount = price.amount * (vatPercent / 100);
        const finalPrice = price.amount + vatAmount;
        return { price: Number(finalPrice.toFixed(2)) };
      }
    }
  }
  return { price: 0 };
}

export const fragmentPrice = (price: number) => price.toFixed(2).split('.');

export const getPoundsFromPrice = (price: number) => fragmentPrice(price)[0];

export const getCoinsFromPrice = (price: number) => fragmentPrice(price)[1];

export async function getPadStackPrices(newVariants: Variant, currency: CurrencyObj, country: CountryCode) {
  const plKey = PAD_STACK_PRODUCT_IDS[PadSizeType.LINER];
  const prKey = PAD_STACK_PRODUCT_IDS[PadSizeType.REGULAR];
  const pnKey = PAD_STACK_PRODUCT_IDS[PadSizeType.SUPER];
  const pnpKey = PAD_STACK_PRODUCT_IDS[PadSizeType.SUPER_PLUS];
  const linerPadStackPrice = (await getProductPrice(plKey, currency, country))?.price || 0;
  const regularPadStackPrice = (await getProductPrice(prKey, currency, country))?.price || 0;
  const nightPadStackPrice = (await getProductPrice(pnKey, currency, country))?.price || 0;
  const nightPlusPadStackPrice = (await getProductPrice(pnpKey, currency, country))?.price || 0;

  return {
    [PAD_STACK_PRODUCT_IDS[PadSizeType.LINER]]: linerPadStackPrice * (newVariants[plKey] || 0),
    [PAD_STACK_PRODUCT_IDS[PadSizeType.REGULAR]]: regularPadStackPrice * (newVariants[prKey] || 0),
    [PAD_STACK_PRODUCT_IDS[PadSizeType.SUPER]]: nightPadStackPrice * (newVariants[pnKey] || 0),
    [PAD_STACK_PRODUCT_IDS[PadSizeType.SUPER_PLUS]]: nightPlusPadStackPrice * (newVariants[pnpKey] || 0)
  };
}

/**
 * Generates a formatted price object based on country and currency conventions.
 * Applies the 'starting-from' prefix if variablePrice or startingFrom flags are provided.
 *
 * @param {number} price - The numeric price value.
 * @param {CountryCode} country - The country code used to decide symbol positioning.
 * @param {CurrencyObj} currency - The currency object containing symbol and letterCode information.
 * @param {(key: string) => string} t - The translation function.
 * @param {boolean} [variablePrice] - Optional. Indicates if the price is variable.
 * @param {boolean} [startingFrom] - Optional. Indicates if the display should include a 'starting-from' prefix.
 * @returns {PriceTextComponents} The formatted price components.
 */
export const getPriceText = (
  price: number,
  country: CountryCode,
  currency: CurrencyObj,
  t: (key: string) => string,
  variablePrice?: boolean,
  startingFrom?: boolean
): PriceTextComponents => {
  // Format the price to two decimals and split into dollars and cents.
  const formattedPrice = price.toFixed(2);
  const [dollars, cents] = formattedPrice.split('.');

  // Determine currency symbol placement and spacing.
  // US/GB: Symbol before, no space.
  // Other countries: Symbol after. Space is added for mainland EU, DE, BG. No space for IE.
  const isBefore = country === CountryCode.US || country === CountryCode.GB;
  const position = isBefore ? 'before' : 'after';

  const countriesWithSpaceAfter = [
    CountryCode.DE,
    CountryCode.BG,
    CountryCode.NL,
    CountryCode.BE,
    CountryCode.IT,
    CountryCode.ES,
    CountryCode.PT,
    CountryCode.FR,
    CountryCode.SE,
    CountryCode.DK,
    CountryCode.NO,
    CountryCode.FI,
    CountryCode.AT,
    CountryCode.CH
  ];
  const needsSpaceAfter = !isBefore && countriesWithSpaceAfter.includes(country);

  let startText = '';
  if (variablePrice || startingFrom) {
    startText = `${t('components.starting-from')} `;
  }

  let prefix = '';
  const main = dollars;
  const isZeroCents = cents === '00';
  const sup = isZeroCents ? '' : cents;
  let suffix = '';

  if (position === 'before') {
    // Currency symbol comes before the price
    prefix = startText + currency.symbol; // No space needed when symbol is before
  } else {
    // Currency symbol comes after the price
    prefix = startText;
    suffix = (needsSpaceAfter ? ' ' : '') + currency.symbol; // Add space conditionally
  }

  // Omit .00 when cents are zero
  const fullString = prefix + main + (isZeroCents ? '' : `.${sup}`) + suffix;

  return {
    fullString,
    prefix,
    main,
    sup,
    suffix
  };
};
