import { CountryCode, Variants, OldVariants } from '@dayetopia/types';
import { PAD_STACK_COUNTS, PAD_STACK_PRODUCT_IDS } from '@constants';
import { BoxItem, BoxState, PadSizeType, PadsBoxStackState, Prices, ProductCode } from '@contracts';

export function getAllPadTypes(): PadSizeType[] {
  return Object.values(PadSizeType);
}

export function areVariantsContainingOldPads(variants: Variants | OldVariants) {
  const allOldPadsKeys = getAllPadTypes();

  if (Array.isArray(variants)) {
    return variants.some((variant) => allOldPadsKeys.includes(variant.id as PadSizeType));
  }

  return Object.keys(variants).some((key) => allOldPadsKeys.includes(key as PadSizeType));
}

export function getPadVariantKeys(country: CountryCode) {
  const isStackBox = isPadsStackBox(country);
  const variantPl = isStackBox ? PAD_STACK_PRODUCT_IDS[PadSizeType.LINER] : PadSizeType.LINER;
  const variantPr = isStackBox ? PAD_STACK_PRODUCT_IDS[PadSizeType.REGULAR] : PadSizeType.REGULAR;
  const variantPn = isStackBox ? PAD_STACK_PRODUCT_IDS[PadSizeType.SUPER] : PadSizeType.SUPER;
  const variantPnp = isStackBox ? PAD_STACK_PRODUCT_IDS[PadSizeType.SUPER_PLUS] : PadSizeType.SUPER_PLUS;

  return {
    [PadSizeType.LINER]: variantPl,
    [PadSizeType.REGULAR]: variantPr,
    [PadSizeType.SUPER]: variantPn,
    [PadSizeType.SUPER_PLUS]: variantPnp
  };
}

export function getPadVariantCounts(country: CountryCode) {
  const isStackBox = isPadsStackBox(country);
  const variantPl = isStackBox ? PAD_STACK_PRODUCT_IDS[PadSizeType.LINER] : PadSizeType.LINER;
  const variantPr = isStackBox ? PAD_STACK_PRODUCT_IDS[PadSizeType.REGULAR] : PadSizeType.REGULAR;
  const variantPn = isStackBox ? PAD_STACK_PRODUCT_IDS[PadSizeType.SUPER] : PadSizeType.SUPER;
  const variantPnp = isStackBox ? PAD_STACK_PRODUCT_IDS[PadSizeType.SUPER_PLUS] : PadSizeType.SUPER_PLUS;

  return {
    [PadSizeType.LINER]: variantPl,
    [PadSizeType.REGULAR]: variantPr,
    [PadSizeType.SUPER]: variantPn,
    [PadSizeType.SUPER_PLUS]: variantPnp
  };
}

export function getPadTypeTextByType(type: PadSizeType) {
  const padSizeTypeTexts = getPadSizeTypeTexts();

  return padSizeTypeTexts[type];
}

export function getPadTypeLongTextByType(type: PadSizeType) {
  const padSizeTypeTexts = getPadSizeTypeLongTexts();

  return padSizeTypeTexts[type];
}

export function getPadTypeShortDescriptionByType(type: PadSizeType) {
  const padSizeTypeTexts = getPadSizeTypeShortDescriptions();

  return padSizeTypeTexts[type];
}

export function getLinerVariantKey(country: CountryCode) {
  const isStackBox = isPadsStackBox(country);

  return isStackBox ? PAD_STACK_PRODUCT_IDS[PadSizeType.LINER] : PadSizeType.LINER;
}

export function getRegularVariantKey(country: CountryCode) {
  const isStackBox = isPadsStackBox(country);

  return isStackBox ? PAD_STACK_PRODUCT_IDS[PadSizeType.REGULAR] : PadSizeType.REGULAR;
}

export function getSuperVariantKey(country: CountryCode) {
  const isStackBox = isPadsStackBox(country);

  return isStackBox ? PAD_STACK_PRODUCT_IDS[PadSizeType.SUPER] : PadSizeType.SUPER;
}

export function getSuperPlusVariantKey(country: CountryCode) {
  const isStackBox = isPadsStackBox(country);

  return isStackBox ? PAD_STACK_PRODUCT_IDS[PadSizeType.SUPER_PLUS] : PadSizeType.SUPER_PLUS;
}

export function getPadStackPriceFromPrices(prices: Prices, padSize: PadSizeType) {
  switch (padSize) {
    case PadSizeType.LINER:
      return prices.linerPadPrice;
    case PadSizeType.REGULAR:
      return prices.regularPadPrice;
    case PadSizeType.SUPER:
      return prices.nightPadPrice;
    case PadSizeType.SUPER_PLUS:
      return prices.nightPlusPadPrice;
    default:
      return -1;
  }
}

export function getPadStackPriceTotalFromPrices(prices: Prices, padsBoxStackState: PadsBoxStackState) {
  return getAllPadTypes().reduce((acc, padSize) => {
    return acc + getPadStackPriceFromPrices(prices, padSize) * padsBoxStackState[padSize];
  }, 0);
}

export function getPadSizeTypeTexts() {
  return {
    [PadSizeType.LINER]: 'components.liners-box',
    [PadSizeType.REGULAR]: 'components.regular-box',
    [PadSizeType.SUPER]: 'components.super-box',
    [PadSizeType.SUPER_PLUS]: 'components.super-plus-box'
  };
}

export function getPadSizeTypeLongTexts() {
  return {
    [PadSizeType.LINER]: 'components.liners',
    [PadSizeType.REGULAR]: 'components.regular',
    [PadSizeType.SUPER]: 'components.super',
    [PadSizeType.SUPER_PLUS]: 'components.super-plus-long'
  };
}

export function getPadSizeTypeShortDescriptions() {
  return {
    [PadSizeType.LINER]: 'components.liners-short-description',
    [PadSizeType.REGULAR]: 'components.regular-short-description',
    [PadSizeType.SUPER]: 'components.super-short-description',
    [PadSizeType.SUPER_PLUS]: 'components.super-plus-short-description'
  };
}

export function isBoxStatePadsStackBox(boxState: BoxState) {
  return !(boxState as BoxState)?.boxSize;
}

export function isPadsAndStackBox(productCode: ProductCode, country: CountryCode) {
  return productCode === ProductCode.Pads && isPadsStackBox(country);
}

export function isPadsStackBox(country: CountryCode) {
  return true;
}

export function convertPadsStackBoxItems(boxItems: BoxItem[]) {
  return boxItems.map((item) => ({
    ...item,
    count: item.count * PAD_STACK_COUNTS[item?.key as keyof typeof PAD_STACK_COUNTS],
    price: (item.price || 0) / PAD_STACK_COUNTS[item?.key as keyof typeof PAD_STACK_COUNTS]
  }));
}

export const getCurrentPadsCount = (boxVariants: Variants, country: CountryCode) => {
  const isStackBox = isPadsStackBox(country);

  if (isStackBox) {
    return getCurrentStackPadsCount(boxVariants);
  }

  return getCurrentLegacyPadsCount(boxVariants);
};

export const getCurrentLegacyPadsCount = (boxVariants: Variants) => {
  if (!boxVariants) return 0;
  const allPadTypes = getAllPadTypes();
  const padIds = [...allPadTypes];
  const variantsArray = Object.entries(boxVariants);
  const pads = Object.fromEntries(variantsArray.filter(([key]) => padIds.some((padId) => padId === key)));
  const padsCount = calculatePadsCount(pads);

  return padsCount;
};

export const getCurrentStackPadsCount = (boxVariants: Variants) => {
  if (!boxVariants) return 0;
  const allPadTypes = getAllPadTypes();
  const padIds = allPadTypes.map((pad) => PAD_STACK_PRODUCT_IDS[pad]);
  const variantsArray = Object.entries(boxVariants);
  const pads = Object.fromEntries(variantsArray.filter(([key]) => padIds.some((padId) => padId === key)));
  const padsCount = calculatePadsCount(pads);

  return padsCount;
};

/**
 * Calculates the total count of pads based on the provided pads configuration.
 *
 * @param {Record<string, number>} pads - An object where keys are pad types and values are their respective counts.
 * @returns {number} The total count of pads.
 */
export function calculatePadsCount(pads: Record<string, number>): number {
  return (
    Number(
      Object.entries(pads).length > 0
        ? Object.entries(pads).reduce((total, [key, count]: any) => {
            const padCount = pads[PAD_STACK_PRODUCT_IDS[key as PadSizeType]] ? count * PAD_STACK_COUNTS[key as PadSizeType] : count;
            return total + padCount;
          }, 0)
        : 0
    ) ?? 0
  );
}
