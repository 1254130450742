import { useEffect } from 'react';
import { initSentry } from '@utils/sentry';

function useInitSentry() {
  useEffect(() => {
    initSentry();
  }, []);
}

export default useInitSentry;
